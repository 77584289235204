.setZindex{
  z-index: 100;
}
.sidenav {
  height: 100%;
  width: 2.7rem;
  z-index: 100;
  padding-top: 16px;
}
.sideNavExpanded{
    height: 100%;
    width: 10rem;
    z-index: 100;
    padding-top: 16px;
  
}hr.solid {
  border-top: 1px solid #ffffff;
  width: 80%;
}
.fa-2x {
  font-size: 1.5em !important;
}
.sidenav a,.sideNavExpanded a {
  padding: 6px 8px 6px 8px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  display: block;
  line-height: 1.2rem;
  width: 10rem;
}

.sidenav a:hover,.sideNavExpanded a:hover {
  color: #f1f1f1;
}@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}
.hideText{
  display: none;
}
.showText{
  display: inline;
  font-size: 15px !important;
  padding-left: 4px;
}


/* ul.slim-items > li:last-child {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-top : 0px;
  display: none;
} */

ul.slim-items > li {
  padding-top : 30px;
}
.sv-normal {
  opacity: 1 !important;
}
.side-nav-override
{
  opacity: 1;
  transform: translateX(0) !important;
  transition-duration: 0s !important
}

.sv-slim-override
{
  display: block !important;
}

@media(max-width : 400px)
{
  .side-nav.wide
  {
    width : 9rem !important;
  }

}
@media(min-width : 401px)
{
  .side-nav.wide
  {
    width : 10rem !important;
  }

}