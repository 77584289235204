@import './fonts/acumin.css';
@font-face {
	font-family: 'Monoton-Regular';
	font-style: normal;
	font-weight: 1000;
	src: url('./fonts/Monoton-Regular.ttf');
	/* IE9 Compat Modes */
	src: local('Monoton-Regular'), local('Monoton-Regular'), url('./fonts/Monoton-Regular.ttf') format('truetype'),
}

@font-face {
	font-family: 'Muli-VariableFont_wght';
	font-style: normal;
	font-weight: 1;
	src: url('./fonts/Muli/Muli-VariableFont_wght.ttf');
	/* IE9 Compat Modes */
	src: local('Muli-VariableFont_wght'), local('Muli-VariableFont_wght'), url('./fonts/Muli/Muli-VariableFont_wght.ttf') format('truetype'),
}

body {
	font-family: "acumin-pro-condensed", sans-serif;
}

.semiCondensedFont {
	font-family: "acumin-pro-semi-condensed", sans-serif !important;
	font-weight: 500 !important;
	font-size: 0.95rem !important;
}

.headerFont {
	font-family: 'Monoton-Regular' !important;
	color: white;
	text-align: center;
	font-size: 40px;
	padding-top: 10px;
}

.flyout {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	justify-content: space-between;
}

.containerBorder {
	border-right: 1px solid grey!important;
	border-bottom: 1px solid grey!important;
	margin-right: 0px !important;
}

.headerHeight {
	height: 60px;
}

.bgColorBlue {
	background-color: #0062AF!important;
}

/* .bgColorBlue {
	background-color: #0062AF!important;
} */

.bgColorDarkBlue {
	background-color: #164E9F!important;
}

.side-nav .collapsible>li a.collapsible-header.active {
	background-color: #164E9F!important;
}

/* Added to remove the default box-sizing */

.mainContainer, .mainContainer:after, .mainContainer:before {
	-webkit-box-sizing: initial;
	-moz-box-sizing: initial;
	box-sizing: content-box;
}

/* #dealer {
	font: Monoton-Regular;
	color: White;
	font-weight: bold;
} */

.home-feature-box .fa {
	font-size: 6rem;
}

.home-feature-box span {
	display: block;
	color: #111;
	font-weight: bold;
	margin-top: 1.5rem;
}

.example-components-list li>a {
	color: #495057;
}

.example-components-list li:last-child>a {
	border-bottom: 0;
}

.example-components-list li>a .fa {
	color: rgba(0, 0, 0, .35);
	float: right;
}

.modal {
	padding-top: 80px !important;
}

.side-nav {
	position: sticky!important;
	top: 0!important;
	height: 100vh;
	box-shadow: none;
	background-color: #164E9F!important;
	padding-top: 3rem;
}

main {
	margin-top: -100vh;
}

.navbar.fixed-top {
	height: 60px;
}

.imageWidth {
	width: 60%;
}

.headerSpace {
	width: 37% !important;
}

.headerTextWidth {
	width: 37%;
}

.fixed-top {
	position: sticky !important;
}

.txtBlueColor {
	color: #0062AF !important;
}

.primaryColor {
	color: #164E9F !important;
}

@media(max-width:900px) {
	.side-nav {
		position: sticky !important;
		margin-bottom: 0;
		top: 0;
		padding-top: 5rem;
	}
	.headerSpace {
		width: 37% !important;
	}
	header, main, footer {
		padding-left: 0;
	}
	.navbar.fixed-top {
		z-index: 999999999;
		height: 65px;
	}
	.imageWidth {
		width: 40%;
	}
	main {
		margin-top: 0;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 40px;
		padding-top: 10px;
	}
}

@media(min-width: 901px) {
	.menu-btn {
		display: none!important;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 40px;
		padding-top: 10px;
	}
}

@media(max-width: 401px) {
	.headerSpace {
		width: 0% !important;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 20px;
		padding-top: 10px;
	}
	#main-content {
		max-width: 90% !important;
		padding-left: 60px !important;
		min-width: 80% !important;
	}
	.show.dropdown-menu.dropdown-default {
		left: -99% !important;
	}
}

@media(min-width: 900px) {
	.show.dropdown-menu.dropdown-default {
		left: -99% !important;
	}
}

@media only screen and (min-width: 400px) and (max-width: 900px) {
	.show.dropdown-menu.dropdown-default {
		left: -50% !important;
	}
}

@media only screen and (max-width: 900px) and (min-width: 401px) {
	.headerSpace {
		width: 0% !important;
	}
	.colP50 {
		padding: 10px 50px !important;
	}
	#main-content {
		max-width: 90% !important;
		padding-left: 60px !important;
		min-width: 80% !important;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 20px;
		padding-top: 10px;
	}
}

.fa-3x {
	font-size: 2.5em !important;
}

.fa-2x {
	font-size: 1.5em !important;
}

#dashboard th {
	color: white;
}

/* .show.dropdown-menu.dropdown-default{
	left: -99% !important;
} */

img {
	cursor: none;
}

.side-nav.wide.slim {
	width: 3rem !important;
}

.scrollbar-container {
	margin: -12px !important;
}

.collapsible.collapsible-accordion>li {
	height: 28px !important;
}

.projectTitle {
	cursor: pointer;
	color: blue;
	text-decoration: underline;
}

/* .MuiButtonBase-root.MuiIconButton-root {
	width: 0.05rem;
} */

.MuiIconButton-root {
	padding: 2px !important;
}

[data-test="col"][disabled] {
	pointer-events: none;
	opacity: 0.7;
}

[data-test="row"][disabled] {
	pointer-events: none;
	opacity: 0.7;
}

[data-test="container"][disabled] {
	pointer-events: none;
	opacity: 0.7;
}

#status .select-wrapper.md-form {
	margin-top: 0rem;
}

#manageUsers .modal-content {
	margin-top: -2.5rem;
	width: 85%;
}

.mngUserHeader {
	font-size: 20px !important;
	font-weight: 600 !important;
	color: #164E9F !important;
	margin: 0.5rem;
}

.mngUserIcons {
	margin-top: 1rem
}

.validateTextField {
	border-color: red !important;
}

.d-flex.w-100.justify-content-between {
	display: none !important;
}

.modal-footer-format {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: .75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(.3rem - 1px);
	border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-dialog-loading {
	width: 200px;
	height: 100px;
	align-items: center;
}

.transparent-modal-dialog {
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0), 0 4px 15px 0 rgba(0, 0, 0, 0) !important;
	background: rgba(0, 151, 19, 0) !important;
}

#pageSpinner {
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0), 0 4px 15px 0 rgba(0, 0, 0, 0) !important;
	background: rgba(0, 151, 19, 0) !important;
}

.alignCenter {
	text-align: center;
}

.alignLeft {
	text-align: left;
}

.labelName {
	color: #0062AF !important;
	font-size: 0.85rem !important;
	;
	font-weight: 600 !important;
	;
	font-family: "acumin-pro-condensed", sans-serif;
}

.labelValue {
	color: grey !important;
	font-size: 0.8rem !important;
	/* font-weight: 500; */
	font-family: "acumin-pro-condensed", sans-serif;
}

/* #feeRow {
	padding-top: 1.25rem;
} */

.notification-container {
	top: 50px !important;
}

div[disabled] {
	pointer-events: none;
	opacity: 0.7;
}

#projectTitle, #projectName {
	text-transform: uppercase;
}

.whiteSpace {
	white-space: pre-line
}

#dashboard {
	white-space: pre-line
}

.FR {
	float: left;
}

.class-name-for-style {
	display: none;
}

#chkSelection {
	padding-left: o !important;
}

.redColor {
	color: red;
	font-size: 20px;
}

.appDate {
	margin: 1.5rem;
	font-size: 16px;
}

#repName {
	font-size: 14px !important;
	font-weight: 600 !important;
	color: black !important;
	padding-top: 0;
	text-decoration: underline !important;
}

#repLabel {
	font-size: 13px !important;
	font-weight: 600 !important;
	color: #164E9F !important;
}

[data-attr='colrep2'] {
	padding-top: 0.5rem !important;
}

[data-attr='colrep2'] [data-test="input"] {
	padding-top: 1.5rem !important;
}

#rep {
	padding: 0 2rem 0 2rem
}

#modalBorder .modal-footer{
	border-top : 0px !important;
}