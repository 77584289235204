input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 476px;
  height: 30px;
  border-radius: 0.12em;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(200px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
  z-index: 100;
}

.suggestion-active, .suggestions li:hover {
  background-color: #3F729B;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  z-index: 101;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.MuiChip-root {
  background-color: #0062AF !important;
  color: beige !important;
}

.MuiChip-deleteIcon {
  color: beige !important;
}

.MuiChip-sizeSmall {
  height: 1rem !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: #0062AF !important;
  color: beige !important;
}

.MuiInputLabel-animated {
  color: #0062AF !important;
}

.md-form label {
  color: grey !important;
  font-size: 0.8rem;
  font-weight: 500;
  left: 0px;
}

.md-form label.active {
  color: #0062AF !important;
  font-size: 1.05rem;
  font-weight: 600;
  left: 0px;
}

/* #projectTitle{
  margin-top: -20px;
}  */

.md-form {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.MuiInputLabel-shrink {
  transform: translate(0, 2px) scale(0.75) !important;
  transform-origin: top left;
}

#talentDetails {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #164E9F !important;
}

.tags-standard {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #0062AF !important;
  /*color: grey !important;*/
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-marginDense {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-weight: 600 !important;
  font-size: 1.05rem !important;
  color: #0062AF !important;
  font-family: "acumin-pro-condensed", sans-serif !important;
}
.MuiInput-underline.Mui-disabled 
{
	border-bottom-style : solid !important;
	border-bottom-width : 1px !important;
}
.MuiInput-underline.Mui-disabled:before
{
	border-bottom-style : none !important;
	border-bottom-width : 1px !important;
}
.custom-control-label-override {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}

.select-wrapper>label.mdb-main-label {
  font-weight: 600 !important;
  color: #0062AF !important;
  font-family: "acumin-pro-condensed", sans-serif !important;
}

.labelHeaders {
  font-size: 17px !important;
  font-weight: 600 !important;
  color: #164E9F !important;
}

.sectionBgColor {
  background-color: #164E9F !important;
  color: white !important;
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: white !important;
}

.sectionContainer {
  padding-top: 3% !important;
  padding-bottom: 1% !important;
}

.custom-control-input {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #0062AF !important;
}

[data-test="controlled-select-input"] {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 530 !important;
  /* color: #164E9F !important; */
  color: black !important;
}

.MuiInputBase-input.MuiInput-input {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 530 !important;
  /* color: #164E9F !important; */
  color: black !important;
}

#talentDetails {
  padding-top: 5% !important
}

.mdb-main-label.active.text-primary {
  margin-top: 0.5rem;
}

.MuiFormControl-root.MuiTextField-root {
  margin-top: 0.35rem;
}

.select-wrapper.md-form {
  margin-top: 0.75rem;
}

#distributors th {
  color: #0062AF !important;
  background-color: white;
  font-weight: 600;
  font-size: 0.9rem;
}

#distributors td {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 530 !important;
  /* color: #164E9F !important; */
  color: black !important;
}

#repDetails .md-form {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#comments, #emailNote {
  height: 5rem;
}

#representative .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  height: 3rem;
  margin-top: -1.5rem;
}

#representatieType .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  margin-top: -0.5rem;
}

#repDetails .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  margin-top: -0.5rem;
}

#representatieType .select-wrapper.md-form.colorful-select.dropdown-primary {
  height: 1rem;
  margin-top: -2rem;
}

#repCreationModal .modal {
  padding-top: 0px !important;
}

#repCreationModal .modal-content {
  margin-top: 1rem;
}

.form-control.form-control {
  height: 2rem;
}

#txtRole, #txtFee {
  margin-top: 1rem;
}

#btn .row {
  margin-right: 6rem;
  margin-left: 6rem;
}

.preloader-wrapper.small {
  width: 1.75rem;
  height: 1.75rem;
}

#projectName, #alias {
  width: 12rem;
}
