body {
  background: #fff;
}

body .table thead {
  background-color: #0062AF!important;
}

body .table thead th {
  border: none;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  bottom: 0.24rem!important;
  font-size: 0.85rem!important;
  
}

table.mdb-dataTable thead .sorting:before:hover, table.mdb-dataTable thead .sorting_asc:before:hover, table.mdb-dataTable thead .sorting_desc:before:hover, table.mdb-dataTable thead .sorting_asc_disabled:before:hover, table.mdb-dataTable thead .sorting_desc_disabled:before:hover, table thead.mdb-dataTable-head .sorting:before:hover, table thead.mdb-dataTable-head .sorting_asc:before:hover, table thead.mdb-dataTable-head .sorting_desc:before:hover, table thead.mdb-dataTable-head .sorting_asc_disabled:before:hover, table thead.mdb-dataTable-head .sorting_desc_disabled:before:hover
{
  bottom: 0.24rem!important;
}
table.mdb-dataTable thead .sorting:before, table.mdb-dataTable thead .sorting:after, table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_asc:after, table.mdb-dataTable thead .sorting_desc:before, table.mdb-dataTable thead .sorting_desc:after, table.mdb-dataTable thead .sorting_asc_disabled:before, table.mdb-dataTable thead .sorting_asc_disabled:after, table.mdb-dataTable thead .sorting_desc_disabled:before, table.mdb-dataTable thead .sorting_desc_disabled:after, table thead.mdb-dataTable-head .sorting:before, table thead.mdb-dataTable-head .sorting:after, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_asc:after, table thead.mdb-dataTable-head .sorting_desc:before, table thead.mdb-dataTable-head .sorting_desc:after, table thead.mdb-dataTable-head .sorting_asc_disabled:before, table thead.mdb-dataTable-head .sorting_asc_disabled:after, table thead.mdb-dataTable-head .sorting_desc_disabled:before, table thead.mdb-dataTable-head .sorting_desc_disabled:after
{
  bottom: 0.24rem!important;
  font-size: 0.85rem!important;
}
table.mdb-dataTable thead .sorting:before, table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_desc:before, table.mdb-dataTable thead .sorting_asc_disabled:before, table.mdb-dataTable thead .sorting_desc_disabled:before, table thead.mdb-dataTable-head .sorting:before, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_desc:before, table thead.mdb-dataTable-head .sorting_asc_disabled:before, table thead.mdb-dataTable-head .sorting_desc_disabled:before
{
  left:-.025rem!important;
}
table.mdb-dataTable thead .sorting:after, table.mdb-dataTable thead .sorting_asc:after, table.mdb-dataTable thead .sorting_desc:after, table.mdb-dataTable thead .sorting_asc_disabled:after, table.mdb-dataTable thead .sorting_desc_disabled:after, table thead.mdb-dataTable-head .sorting:after, table thead.mdb-dataTable-head .sorting_asc:after, table thead.mdb-dataTable-head .sorting_desc:after, table thead.mdb-dataTable-head .sorting_asc_disabled:after, table thead.mdb-dataTable-head .sorting_desc_disabled:after
{
  left:-.025rem!important;
}

body table thead.mdb-dataTable-head .sorting_desc:before, table.mdb-dataTable thead .sorting_desc:before, table thead.mdb-dataTable-head .sorting_desc:after, table.mdb-dataTable thead .sorting_desc:after, body table thead.mdb-dataTable-head .sorting_asc:before, table.mdb-dataTable thead .sorting_asc:before, table thead.mdb-dataTable-head .sorting_asc:after, table.mdb-dataTable thead .sorting_asc:after {
  bottom: 0.25rem!important;
  font-size: 0.85rem!important;
}

.modal-backdrop.show {
  opacity: 0.125!important;
}

table.mdb-dataTable thead th, table thead.mdb-dataTable-head th {
  font-weight: 280!important;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover {
  color: #fff;
}

.btn.btn-sm {
  width: 100px;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

#categories>.col-md-4.mb-5 {
  margin-bottom: 8rem !important;
}

.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -125px;
}

.sectionPage::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  background: url('https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg');
  background-position: center center;
  background-size: cover;
  filter: opacity(.1);
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

.textAlignRight {
  text-align: right !important;
}

.textAlignCenter {
  text-align: center !important;
}

.TP20 {
  padding-top: 20px;
}



table.table th, table.table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.project {
  color: #164E9F!important;
  text-decoration: underline !important;
}

/* mouse over link */

.project:hover {
  color: #164E9F !important;
  text-decoration: underline !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}

/* selected link */

.project:active {
  color: #164E9F !important;
}

.statusText {
  font-size: 10px !important;
  color: red !important;
}

.fa-rotate-125
{
  transform: rotate(125deg);
}

.statusDataTimePara
{
 margin-bottom:0.6em;
 line-height: 10px; 
 font-size: 0.66rem!important;
 font-weight:normal!important;
 flex-wrap:nowrap;

}

.statushistoryPara
{
 font-family: "acumin-pro-condensed", sans-serif !important;
 margin:0 0 5px 0;
 /* line-height: 14px;  */
 font-size: 0.8rem !important;
 font-weight: 0.9rem !important;
 flex-wrap: nowrap;
 color : black;

}
.statushistoryDiv1{
  width:35%;
  float:left;
  margin:0;
  padding:0;
  flex-wrap:nowrap;
}
.statushistoryDiv2{
  width:65%;
  float:left;
  margin:0;
  padding:0;
  flex-wrap:nowrap;


}
.statusDataTimeDiv{
  width:80%;
  float:left;
  margin:0;
  padding:0;
  flex-wrap:nowrap;

}
.statushistoryMain{
  overflow: hidden;
}
.table-responsive{
  overflow-x: initial !important;
}
