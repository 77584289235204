.btn-default {
    background-color: #164E9F!important;
}

[data-test="modal-header"] {
    font-family: "acumin-pro-condensed", sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #164E9F !important;
}

.dropdown-content li>span {
    display: block;
    padding: .5rem;
    font-size: 1rem;
    /*color: #164E9F !important;*/
    color : grey !important;
}


.dropdown-content li>span:hover {
    color: #164E9F !important;
}


.MuiAutocomplete-paper
{
    font-size : .8rem !important;
}

.MuiAutocomplete-option
{
    min-height: 30px !important;
}

.form-control-sm
{
    font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}
.form-control
{
    font-family: "acumin-pro-condensed", sans-serif !important;
    font-size: 0.8rem !important;
    font-weight: 530 !important;
    /* color: #164E9F !important; */
    color: black !important;
}