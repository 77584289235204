@import url(https://p.typekit.net/p.css?s=1&k=rkb8ioa&ht=tk&f=26053.26054.26056.26062.25998.25999.26000.26001.26016.26017.26018.26019.26036.26037.26046.26047.26072.26073.26086.26087&a=34283824&app=typekit&e=css);
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * acumin-pro:
 *   - http://typekit.com/eulas/00000000000000003b9acafc
 *   - http://typekit.com/eulas/00000000000000003b9acafd
 *   - http://typekit.com/eulas/00000000000000003b9acaf7
 *   - http://typekit.com/eulas/00000000000000003b9acaf6
 * acumin-pro-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9acb0e
 *   - http://typekit.com/eulas/00000000000000003b9acb0f
 *   - http://typekit.com/eulas/00000000000000003b9acb08
 *   - http://typekit.com/eulas/00000000000000003b9acb09
 * acumin-pro-extra-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9acb20
 *   - http://typekit.com/eulas/00000000000000003b9acb21
 *   - http://typekit.com/eulas/00000000000000003b9acb1a
 *   - http://typekit.com/eulas/00000000000000003b9acb1b
 * acumin-pro-semi-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9acb33
 *   - http://typekit.com/eulas/00000000000000003b9acb34
 *   - http://typekit.com/eulas/00000000000000003b9acb2d
 *   - http://typekit.com/eulas/00000000000000003b9acb2e
 * acumin-pro-wide:
 *   - http://typekit.com/eulas/00000000000000003b9acb45
 *   - http://typekit.com/eulas/00000000000000003b9acb46
 *   - http://typekit.com/eulas/00000000000000003b9acb3f
 *   - http://typekit.com/eulas/00000000000000003b9acb40
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-05-22 04:48:07 UTC"}*/

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"acumin-pro-condensed";
src:url("https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"acumin-pro-condensed";
src:url("https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"acumin-pro-condensed";
src:url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"acumin-pro-condensed";
src:url("https://use.typekit.net/af/9832ec/00000000000000003b9acb09/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/9832ec/00000000000000003b9acb09/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/9832ec/00000000000000003b9acb09/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"acumin-pro-extra-condensed";
src:url("https://use.typekit.net/af/c4767b/00000000000000003b9acb20/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/c4767b/00000000000000003b9acb20/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/c4767b/00000000000000003b9acb20/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"acumin-pro-extra-condensed";
src:url("https://use.typekit.net/af/289661/00000000000000003b9acb21/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/289661/00000000000000003b9acb21/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/289661/00000000000000003b9acb21/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"acumin-pro-extra-condensed";
src:url("https://use.typekit.net/af/f2b2eb/00000000000000003b9acb1a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f2b2eb/00000000000000003b9acb1a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f2b2eb/00000000000000003b9acb1a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"acumin-pro-extra-condensed";
src:url("https://use.typekit.net/af/c830f2/00000000000000003b9acb1b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/c830f2/00000000000000003b9acb1b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/c830f2/00000000000000003b9acb1b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"acumin-pro-semi-condensed";
src:url("https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"acumin-pro-semi-condensed";
src:url("https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"acumin-pro-semi-condensed";
src:url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"acumin-pro-semi-condensed";
src:url("https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"acumin-pro-wide";
src:url("https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"acumin-pro-wide";
src:url("https://use.typekit.net/af/72fcf6/00000000000000003b9acb46/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/72fcf6/00000000000000003b9acb46/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/72fcf6/00000000000000003b9acb46/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"acumin-pro-wide";
src:url("https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"acumin-pro-wide";
src:url("https://use.typekit.net/af/a77ed7/00000000000000003b9acb40/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/a77ed7/00000000000000003b9acb40/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/a77ed7/00000000000000003b9acb40/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

.tk-acumin-pro { font-family: "acumin-pro",sans-serif; }
.tk-acumin-pro-condensed { font-family: "acumin-pro-condensed",sans-serif; }
.tk-acumin-pro-extra-condensed { font-family: "acumin-pro-extra-condensed",sans-serif; }
.tk-acumin-pro-semi-condensed { font-family: "acumin-pro-semi-condensed",sans-serif; }
.tk-acumin-pro-wide { font-family: "acumin-pro-wide",sans-serif; }
@font-face {
	font-family: 'Monoton-Regular';
	font-style: normal;
	font-weight: 1000;
	src: url(../../static/media/Monoton-Regular.411051cb.ttf);
	/* IE9 Compat Modes */
	src: local('Monoton-Regular'), local('Monoton-Regular'), url(../../static/media/Monoton-Regular.411051cb.ttf) format('truetype'),
}

@font-face {
	font-family: 'Muli-VariableFont_wght';
	font-style: normal;
	font-weight: 1;
	src: url(../../static/media/Muli-VariableFont_wght.e8efee92.ttf);
	/* IE9 Compat Modes */
	src: local('Muli-VariableFont_wght'), local('Muli-VariableFont_wght'), url(../../static/media/Muli-VariableFont_wght.e8efee92.ttf) format('truetype'),
}

body {
	font-family: "acumin-pro-condensed", sans-serif;
}

.semiCondensedFont {
	font-family: "acumin-pro-semi-condensed", sans-serif !important;
	font-weight: 500 !important;
	font-size: 0.95rem !important;
}

.headerFont {
	font-family: 'Monoton-Regular' !important;
	color: white;
	text-align: center;
	font-size: 40px;
	padding-top: 10px;
}

.flyout {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	justify-content: space-between;
}

.containerBorder {
	border-right: 1px solid grey!important;
	border-bottom: 1px solid grey!important;
	margin-right: 0px !important;
}

.headerHeight {
	height: 60px;
}

.bgColorBlue {
	background-color: #0062AF!important;
}

/* .bgColorBlue {
	background-color: #0062AF!important;
} */

.bgColorDarkBlue {
	background-color: #164E9F!important;
}

.side-nav .collapsible>li a.collapsible-header.active {
	background-color: #164E9F!important;
}

/* Added to remove the default box-sizing */

.mainContainer, .mainContainer:after, .mainContainer:before {
	box-sizing: content-box;
}

/* #dealer {
	font: Monoton-Regular;
	color: White;
	font-weight: bold;
} */

.home-feature-box .fa {
	font-size: 6rem;
}

.home-feature-box span {
	display: block;
	color: #111;
	font-weight: bold;
	margin-top: 1.5rem;
}

.example-components-list li>a {
	color: #495057;
}

.example-components-list li:last-child>a {
	border-bottom: 0;
}

.example-components-list li>a .fa {
	color: rgba(0, 0, 0, .35);
	float: right;
}

.modal {
	padding-top: 80px !important;
}

.side-nav {
	position: -webkit-sticky!important;
	position: sticky!important;
	top: 0!important;
	height: 100vh;
	box-shadow: none;
	background-color: #164E9F!important;
	padding-top: 3rem;
}

main {
	margin-top: -100vh;
}

.navbar.fixed-top {
	height: 60px;
}

.imageWidth {
	width: 60%;
}

.headerSpace {
	width: 37% !important;
}

.headerTextWidth {
	width: 37%;
}

.fixed-top {
	position: -webkit-sticky !important;
	position: sticky !important;
}

.txtBlueColor {
	color: #0062AF !important;
}

.primaryColor {
	color: #164E9F !important;
}

@media(max-width:900px) {
	.side-nav {
		position: -webkit-sticky !important;
		position: sticky !important;
		margin-bottom: 0;
		top: 0;
		padding-top: 5rem;
	}
	.headerSpace {
		width: 37% !important;
	}
	header, main, footer {
		padding-left: 0;
	}
	.navbar.fixed-top {
		z-index: 999999999;
		height: 65px;
	}
	.imageWidth {
		width: 40%;
	}
	main {
		margin-top: 0;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 40px;
		padding-top: 10px;
	}
}

@media(min-width: 901px) {
	.menu-btn {
		display: none!important;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 40px;
		padding-top: 10px;
	}
}

@media(max-width: 401px) {
	.headerSpace {
		width: 0% !important;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 20px;
		padding-top: 10px;
	}
	#main-content {
		max-width: 90% !important;
		padding-left: 60px !important;
		min-width: 80% !important;
	}
	.show.dropdown-menu.dropdown-default {
		left: -99% !important;
	}
}

@media(min-width: 900px) {
	.show.dropdown-menu.dropdown-default {
		left: -99% !important;
	}
}

@media only screen and (min-width: 400px) and (max-width: 900px) {
	.show.dropdown-menu.dropdown-default {
		left: -50% !important;
	}
}

@media only screen and (max-width: 900px) and (min-width: 401px) {
	.headerSpace {
		width: 0% !important;
	}
	.colP50 {
		padding: 10px 50px !important;
	}
	#main-content {
		max-width: 90% !important;
		padding-left: 60px !important;
		min-width: 80% !important;
	}
	.headerFont {
		font-family: 'Monoton-Regular' !important;
		color: white;
		text-align: center;
		font-size: 20px;
		padding-top: 10px;
	}
}

.fa-3x {
	font-size: 2.5em !important;
}

.fa-2x {
	font-size: 1.5em !important;
}

#dashboard th {
	color: white;
}

/* .show.dropdown-menu.dropdown-default{
	left: -99% !important;
} */

img {
	cursor: none;
}

.side-nav.wide.slim {
	width: 3rem !important;
}

.scrollbar-container {
	margin: -12px !important;
}

.collapsible.collapsible-accordion>li {
	height: 28px !important;
}

.projectTitle {
	cursor: pointer;
	color: blue;
	text-decoration: underline;
}

/* .MuiButtonBase-root.MuiIconButton-root {
	width: 0.05rem;
} */

.MuiIconButton-root {
	padding: 2px !important;
}

[data-test="col"][disabled] {
	pointer-events: none;
	opacity: 0.7;
}

[data-test="row"][disabled] {
	pointer-events: none;
	opacity: 0.7;
}

[data-test="container"][disabled] {
	pointer-events: none;
	opacity: 0.7;
}

#status .select-wrapper.md-form {
	margin-top: 0rem;
}

#manageUsers .modal-content {
	margin-top: -2.5rem;
	width: 85%;
}

.mngUserHeader {
	font-size: 20px !important;
	font-weight: 600 !important;
	color: #164E9F !important;
	margin: 0.5rem;
}

.mngUserIcons {
	margin-top: 1rem
}

.validateTextField {
	border-color: red !important;
}

.d-flex.w-100.justify-content-between {
	display: none !important;
}

.modal-footer-format {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: .75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(.3rem - 1px);
	border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-dialog-loading {
	width: 200px;
	height: 100px;
	align-items: center;
}

.transparent-modal-dialog {
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0), 0 4px 15px 0 rgba(0, 0, 0, 0) !important;
	background: rgba(0, 151, 19, 0) !important;
}

#pageSpinner {
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0), 0 4px 15px 0 rgba(0, 0, 0, 0) !important;
	background: rgba(0, 151, 19, 0) !important;
}

.alignCenter {
	text-align: center;
}

.alignLeft {
	text-align: left;
}

.labelName {
	color: #0062AF !important;
	font-size: 0.85rem !important;
	;
	font-weight: 600 !important;
	;
	font-family: "acumin-pro-condensed", sans-serif;
}

.labelValue {
	color: grey !important;
	font-size: 0.8rem !important;
	/* font-weight: 500; */
	font-family: "acumin-pro-condensed", sans-serif;
}

/* #feeRow {
	padding-top: 1.25rem;
} */

.notification-container {
	top: 50px !important;
}

div[disabled] {
	pointer-events: none;
	opacity: 0.7;
}

#projectTitle, #projectName {
	text-transform: uppercase;
}

.whiteSpace {
	white-space: pre-line
}

#dashboard {
	white-space: pre-line
}

.FR {
	float: left;
}

.class-name-for-style {
	display: none;
}

#chkSelection {
	padding-left: o !important;
}

.redColor {
	color: red;
	font-size: 20px;
}

.appDate {
	margin: 1.5rem;
	font-size: 16px;
}

#repName {
	font-size: 14px !important;
	font-weight: 600 !important;
	color: black !important;
	padding-top: 0;
	text-decoration: underline !important;
}

#repLabel {
	font-size: 13px !important;
	font-weight: 600 !important;
	color: #164E9F !important;
}

[data-attr='colrep2'] {
	padding-top: 0.5rem !important;
}

[data-attr='colrep2'] [data-test="input"] {
	padding-top: 1.5rem !important;
}

#rep {
	padding: 0 2rem 0 2rem
}

#modalBorder .modal-footer{
	border-top : 0px !important;
}
.setZindex{
  z-index: 100;
}
.sidenav {
  height: 100%;
  width: 2.7rem;
  z-index: 100;
  padding-top: 16px;
}
.sideNavExpanded{
    height: 100%;
    width: 10rem;
    z-index: 100;
    padding-top: 16px;
  
}hr.solid {
  border-top: 1px solid #ffffff;
  width: 80%;
}
.fa-2x {
  font-size: 1.5em !important;
}
.sidenav a,.sideNavExpanded a {
  padding: 6px 8px 6px 8px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  display: block;
  line-height: 1.2rem;
  width: 10rem;
}

.sidenav a:hover,.sideNavExpanded a:hover {
  color: #f1f1f1;
}@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}
.hideText{
  display: none;
}
.showText{
  display: inline;
  font-size: 15px !important;
  padding-left: 4px;
}


/* ul.slim-items > li:last-child {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-top : 0px;
  display: none;
} */

ul.slim-items > li {
  padding-top : 30px;
}
.sv-normal {
  opacity: 1 !important;
}
.side-nav-override
{
  opacity: 1;
  transform: translateX(0) !important;
  transition-duration: 0s !important
}

.sv-slim-override
{
  display: block !important;
}

@media(max-width : 400px)
{
  .side-nav.wide
  {
    width : 9rem !important;
  }

}
@media(min-width : 401px)
{
  .side-nav.wide
  {
    width : 10rem !important;
  }

}
.btn-default {
    background-color: #164E9F!important;
}

[data-test="modal-header"] {
    font-family: "acumin-pro-condensed", sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #164E9F !important;
}

.dropdown-content li>span {
    display: block;
    padding: .5rem;
    font-size: 1rem;
    /*color: #164E9F !important;*/
    color : grey !important;
}


.dropdown-content li>span:hover {
    color: #164E9F !important;
}


.MuiAutocomplete-paper
{
    font-size : .8rem !important;
}

.MuiAutocomplete-option
{
    min-height: 30px !important;
}

.form-control-sm
{
    font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}
.form-control
{
    font-family: "acumin-pro-condensed", sans-serif !important;
    font-size: 0.8rem !important;
    font-weight: 530 !important;
    /* color: #164E9F !important; */
    color: black !important;
}
input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 476px;
  height: 30px;
  border-radius: 0.12em;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(200px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
  z-index: 100;
}

.suggestion-active, .suggestions li:hover {
  background-color: #3F729B;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  z-index: 101;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.MuiChip-root {
  background-color: #0062AF !important;
  color: beige !important;
}

.MuiChip-deleteIcon {
  color: beige !important;
}

.MuiChip-sizeSmall {
  height: 1rem !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: #0062AF !important;
  color: beige !important;
}

.MuiInputLabel-animated {
  color: #0062AF !important;
}

.md-form label {
  color: grey !important;
  font-size: 0.8rem;
  font-weight: 500;
  left: 0px;
}

.md-form label.active {
  color: #0062AF !important;
  font-size: 1.05rem;
  font-weight: 600;
  left: 0px;
}

/* #projectTitle{
  margin-top: -20px;
}  */

.md-form {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.MuiInputLabel-shrink {
  transform: translate(0, 2px) scale(0.75) !important;
  transform-origin: top left;
}

#talentDetails {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #164E9F !important;
}

.tags-standard {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #0062AF !important;
  /*color: grey !important;*/
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-marginDense {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-weight: 600 !important;
  font-size: 1.05rem !important;
  color: #0062AF !important;
  font-family: "acumin-pro-condensed", sans-serif !important;
}
.MuiInput-underline.Mui-disabled 
{
	border-bottom-style : solid !important;
	border-bottom-width : 1px !important;
}
.MuiInput-underline.Mui-disabled:before
{
	border-bottom-style : none !important;
	border-bottom-width : 1px !important;
}
.custom-control-label-override {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  /* color: #164E9F !important; */
  color: grey !important;
}

.select-wrapper>label.mdb-main-label {
  font-weight: 600 !important;
  color: #0062AF !important;
  font-family: "acumin-pro-condensed", sans-serif !important;
}

.labelHeaders {
  font-size: 17px !important;
  font-weight: 600 !important;
  color: #164E9F !important;
}

.sectionBgColor {
  background-color: #164E9F !important;
  color: white !important;
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: white !important;
}

.sectionContainer {
  padding-top: 3% !important;
  padding-bottom: 1% !important;
}

.custom-control-input {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #0062AF !important;
}

[data-test="controlled-select-input"] {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 530 !important;
  /* color: #164E9F !important; */
  color: black !important;
}

.MuiInputBase-input.MuiInput-input {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 530 !important;
  /* color: #164E9F !important; */
  color: black !important;
}

#talentDetails {
  padding-top: 5% !important
}

.mdb-main-label.active.text-primary {
  margin-top: 0.5rem;
}

.MuiFormControl-root.MuiTextField-root {
  margin-top: 0.35rem;
}

.select-wrapper.md-form {
  margin-top: 0.75rem;
}

#distributors th {
  color: #0062AF !important;
  background-color: white;
  font-weight: 600;
  font-size: 0.9rem;
}

#distributors td {
  font-family: "acumin-pro-condensed", sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 530 !important;
  /* color: #164E9F !important; */
  color: black !important;
}

#repDetails .md-form {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#comments, #emailNote {
  height: 5rem;
}

#representative .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  height: 3rem;
  margin-top: -1.5rem;
}

#representatieType .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  margin-top: -0.5rem;
}

#repDetails .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  margin-top: -0.5rem;
}

#representatieType .select-wrapper.md-form.colorful-select.dropdown-primary {
  height: 1rem;
  margin-top: -2rem;
}

#repCreationModal .modal {
  padding-top: 0px !important;
}

#repCreationModal .modal-content {
  margin-top: 1rem;
}

.form-control.form-control {
  height: 2rem;
}

#txtRole, #txtFee {
  margin-top: 1rem;
}

#btn .row {
  margin-right: 6rem;
  margin-left: 6rem;
}

.preloader-wrapper.small {
  width: 1.75rem;
  height: 1.75rem;
}

#projectName, #alias {
  width: 12rem;
}

body {
  background: #fff;
}

body .table thead {
  background-color: #0062AF!important;
}

body .table thead th {
  border: none;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  bottom: 0.24rem!important;
  font-size: 0.85rem!important;
  
}

table.mdb-dataTable thead .sorting:before:hover, table.mdb-dataTable thead .sorting_asc:before:hover, table.mdb-dataTable thead .sorting_desc:before:hover, table.mdb-dataTable thead .sorting_asc_disabled:before:hover, table.mdb-dataTable thead .sorting_desc_disabled:before:hover, table thead.mdb-dataTable-head .sorting:before:hover, table thead.mdb-dataTable-head .sorting_asc:before:hover, table thead.mdb-dataTable-head .sorting_desc:before:hover, table thead.mdb-dataTable-head .sorting_asc_disabled:before:hover, table thead.mdb-dataTable-head .sorting_desc_disabled:before:hover
{
  bottom: 0.24rem!important;
}
table.mdb-dataTable thead .sorting:before, table.mdb-dataTable thead .sorting:after, table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_asc:after, table.mdb-dataTable thead .sorting_desc:before, table.mdb-dataTable thead .sorting_desc:after, table.mdb-dataTable thead .sorting_asc_disabled:before, table.mdb-dataTable thead .sorting_asc_disabled:after, table.mdb-dataTable thead .sorting_desc_disabled:before, table.mdb-dataTable thead .sorting_desc_disabled:after, table thead.mdb-dataTable-head .sorting:before, table thead.mdb-dataTable-head .sorting:after, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_asc:after, table thead.mdb-dataTable-head .sorting_desc:before, table thead.mdb-dataTable-head .sorting_desc:after, table thead.mdb-dataTable-head .sorting_asc_disabled:before, table thead.mdb-dataTable-head .sorting_asc_disabled:after, table thead.mdb-dataTable-head .sorting_desc_disabled:before, table thead.mdb-dataTable-head .sorting_desc_disabled:after
{
  bottom: 0.24rem!important;
  font-size: 0.85rem!important;
}
table.mdb-dataTable thead .sorting:before, table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_desc:before, table.mdb-dataTable thead .sorting_asc_disabled:before, table.mdb-dataTable thead .sorting_desc_disabled:before, table thead.mdb-dataTable-head .sorting:before, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_desc:before, table thead.mdb-dataTable-head .sorting_asc_disabled:before, table thead.mdb-dataTable-head .sorting_desc_disabled:before
{
  left:-.025rem!important;
}
table.mdb-dataTable thead .sorting:after, table.mdb-dataTable thead .sorting_asc:after, table.mdb-dataTable thead .sorting_desc:after, table.mdb-dataTable thead .sorting_asc_disabled:after, table.mdb-dataTable thead .sorting_desc_disabled:after, table thead.mdb-dataTable-head .sorting:after, table thead.mdb-dataTable-head .sorting_asc:after, table thead.mdb-dataTable-head .sorting_desc:after, table thead.mdb-dataTable-head .sorting_asc_disabled:after, table thead.mdb-dataTable-head .sorting_desc_disabled:after
{
  left:-.025rem!important;
}

body table thead.mdb-dataTable-head .sorting_desc:before, table.mdb-dataTable thead .sorting_desc:before, table thead.mdb-dataTable-head .sorting_desc:after, table.mdb-dataTable thead .sorting_desc:after, body table thead.mdb-dataTable-head .sorting_asc:before, table.mdb-dataTable thead .sorting_asc:before, table thead.mdb-dataTable-head .sorting_asc:after, table.mdb-dataTable thead .sorting_asc:after {
  bottom: 0.25rem!important;
  font-size: 0.85rem!important;
}

.modal-backdrop.show {
  opacity: 0.125!important;
}

table.mdb-dataTable thead th, table thead.mdb-dataTable-head th {
  font-weight: 280!important;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover {
  color: #fff;
}

.btn.btn-sm {
  width: 100px;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

#categories>.col-md-4.mb-5 {
  margin-bottom: 8rem !important;
}

.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -125px;
}

.sectionPage::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  background: url('https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg');
  background-position: center center;
  background-size: cover;
  -webkit-filter: opacity(.1);
          filter: opacity(.1);
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

.textAlignRight {
  text-align: right !important;
}

.textAlignCenter {
  text-align: center !important;
}

.TP20 {
  padding-top: 20px;
}



table.table th, table.table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.project {
  color: #164E9F!important;
  text-decoration: underline !important;
}

/* mouse over link */

.project:hover {
  color: #164E9F !important;
  text-decoration: underline !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}

/* selected link */

.project:active {
  color: #164E9F !important;
}

.statusText {
  font-size: 10px !important;
  color: red !important;
}

.fa-rotate-125
{
  transform: rotate(125deg);
}

.statusDataTimePara
{
 margin-bottom:0.6em;
 line-height: 10px; 
 font-size: 0.66rem!important;
 font-weight:normal!important;
 flex-wrap:nowrap;

}

.statushistoryPara
{
 font-family: "acumin-pro-condensed", sans-serif !important;
 margin:0 0 5px 0;
 /* line-height: 14px;  */
 font-size: 0.8rem !important;
 font-weight: 0.9rem !important;
 flex-wrap: nowrap;
 color : black;

}
.statushistoryDiv1{
  width:35%;
  float:left;
  margin:0;
  padding:0;
  flex-wrap:nowrap;
}
.statushistoryDiv2{
  width:65%;
  float:left;
  margin:0;
  padding:0;
  flex-wrap:nowrap;


}
.statusDataTimeDiv{
  width:80%;
  float:left;
  margin:0;
  padding:0;
  flex-wrap:nowrap;

}
.statushistoryMain{
  overflow: hidden;
}
.table-responsive{
  overflow-x: visible !important;
  overflow-x: initial !important;
}

